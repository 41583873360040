<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Лимиты</div>
        <b-tabs v-model="tabIndx">
            <b-tab title="Расходы">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(7)">Режим «Лимиты» <i class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Данный режим предназначен для ввода, корректировки и просмотра прогнозного объема
                                    поступлений в местный бюджет и лимитов расходов администраторов бюджетных программ
                                    на предстоящий плановый период. </p>
                                <p>Режим состоит из следующих разделов:</p>
                                <p><span class="red bold">Доходы</span><br> – распределение прогнозного объема
                                    поступлений между уровнями бюджетов.</p>
                                <p><span class="red bold">Нормативы распределения</span><br> – ведение таблицы
                                    распределения поступлений бюджета между уровнями бюджетов (приказ МФ РК №404).</p>
                                <p><span class="red bold">Расходы</span><br> – прогнозирование лимитов расходов
                                    администраторов бюджетных программ.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadSvodAbpReport">Свод по АБП</b-dropdown-item>
                        <b-dropdown-item @click="downloadLimitReport">Лимиты расходов по БП</b-dropdown-item>
                        <b-dropdown-item @click="downloadExpenseLimits">Лимиты расходов АБП</b-dropdown-item>
                        <b-dropdown-item @click="referToFormForExcel">Информация о применяемых коэффициентах</b-dropdown-item>
                    </b-dropdown>
                </div>
                <template>
                    <budget-limits2 ref="budgetlimits"></budget-limits2>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetLimits2 from '@/modules/budget-limitCosts/BudgetLimits2.vue';

export default {
    name: 'BudgetLimit',
    components: {BudgetLimits2},
    data() {
        return {
            year: null,
            obl: null, // '45'
            region: null, // '450101',
            variant: null,
            tabIndx: 0,

            listReg: [],
            selectedABP: {id: 0, child: []},
            selectedPRG: {id: 0, child: []},
            selectedPPR: {id: 0},
            headers: null,
            currentYear: null,
            informSpravka: [],
            listVariants: [],
            userRegion: null
        };
    },

    async mounted() {
        this.year = this.currentYear = new Date().getFullYear();
        await this.getObl();
        await this.loadRegions();
    },


};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>